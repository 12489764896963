.App {
  background-color: #f1f1f1;
}

.App .navbar-brand {
  font-weight: bold;
}

.navbar {
  padding: 30px 0;
  background-color: #fff;
  margin: 0;
}

.navbar-brand {
  padding: 0;
  margin: 0 !important;
  height: auto;
}
